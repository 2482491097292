import Header from '../../1_Header/Container/Header';
import Services from '../../2_Main/Services/Services';
import Footer from '../../3_Footer/Footer';

function Usluga() {
  return (
    <>
      <Header />
      <Services />
      <Footer />
    </>
  );
}

export default Usluga;
