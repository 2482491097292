import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import foto1 from '../../../img/Uslugi/sofa.png';
import foto2 from '../../../img/Uslugi/sofa2.png';
import './Uslugi.css';

function Foto1() {
  return (
    <>
      <img src={foto1} alt="uslugi" />
    </>
  );
}
function Foto2() {
  return (
    <>
      <img src={foto2} alt="uslugi" />
    </>
  );
}

function SwiperUslugi() {
  return (
    <Swiper
      navigation
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={{ type: 'fraction' }}
      modules={[EffectCoverflow, Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide className="swiper-uslugi">
        <Foto1 />
      </SwiperSlide>
      <SwiperSlide className="swiper-uslugi">
        <Foto2 />
      </SwiperSlide>
    </Swiper>
  );
}
export default SwiperUslugi;
