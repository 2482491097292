import SwiperSliders from '../../SwiperSlider/Swiper';

function SliderCourusel() {
  return (
    <>
      <SwiperSliders />
    </>
  );
}

export default SliderCourusel;
