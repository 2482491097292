import { Link } from 'react-router-dom';
import { FaTiktok } from 'react-icons/fa6';
import { FaFacebookF } from 'react-icons/fa6';
import { CiInstagram } from 'react-icons/ci';
import { FaTelegram } from 'react-icons/fa6';
import { FaWhatsapp } from 'react-icons/fa6';

import './LinkSocial.css';

function LinkSocial(props) {
  const { close = 'close-social' } = props;
  return (
    <div className={`header__list-social-media ${close}`}>
      <div className="header__social-link-item item-instagram">
        <Link
          to="#"
          title="instagram"
          alt="tiktok"
          target="_blank"
          className="header__instargam"
        >
          <CiInstagram className="social_logo instagram" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61564481455485"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <FaFacebookF className="social_logo" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.tiktok.com/@cleaning_5577?_t=8pZQn48eb5y&_r=1"
          target="_blank"
          alt="tiktok"
          title="tiktok"
          className="header_facebook"
        >
          <FaTiktok className="social_logo" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://t.me/yevgeniu_pityanin"
          target="_blank"
          alt="telegram"
          title="telegram"
          className="header_facebook"
        >
          <FaTelegram className="social_logo" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://wa.me/48666561845"
          target="_blank"
          alt="whatsapp"
          title="whatsapp"
          className="header_facebook"
        >
          <FaWhatsapp className="social_logo" />
        </Link>
      </div>
    </div>
  );
}
export default LinkSocial;
