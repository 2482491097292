import './topheader.css';
import MyEmail from '../../../UI/MyEmail/MyEmail';
import MyPhone from '../../../UI/MyPhone/MyPhone';
import LinkSocial from '../SocialLinks/LinkSocial';
import ButtomsLang from '../../../UI/Buttons/Buttons';

function TopHeader() {
  return (
    <>
      <div className="header-top">
        <div className="container">
          <div className="header-top__wrapper">
            <div className="header-top__contacts">
              <MyEmail>
                {' '}
                <span>samurai.dry.cleaning@gmail.com</span>{' '}
              </MyEmail>
              <MyPhone />
            </div>
            <div className="wrapper__social-lang">
              <div className="header-top__social">
                <LinkSocial />
              </div>
              <div className="header-top__lang">
                <ButtomsLang />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopHeader;
