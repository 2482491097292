import HeaderMain from '../Header/Header';
import TopHeader from '../TopHeader/TopHeader';

function Header() {
  return (
    <>
      <TopHeader />
      <HeaderMain />
    </>
  );
}
export default Header;
