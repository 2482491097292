import karcher from '../../../img/logo/korher.jpg';
import './Karcher.css';
function Karcher() {
  return (
    <div className="header__karcher">
      <img src={karcher} alt="karcher" className="karcher" />
    </div>
  );
}
export default Karcher;
