import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './SwiperSlider.css';
import { useRef } from 'react';
import { useProject } from '../../tools/ProviderContext';

function SliderOne() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className="slider">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">
            Pranie tapicerki meblowej w Krakowie (+30 km)
          </h2>
          <p className="slider__text-p">
            {' '}
            Profesjonalna pielęgnacja mebli, usuwanie plam, usuwanie zapachów{' '}
          </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Zostaw wniosek
          </button>
          <div className="slider__garantia">
            <span>
              GWARANTUJEMY ZWROT 100% OPLATY, JESLI KLIENT NIE BEDZIE ZADOWOLONY
              Z EFEKTU PRANIA !!!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
function SliderTwo() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className="sliderCar">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">
            Detailing , pranie tapicerki samochodu
          </h2>
          <p className="slider__text-p">
            {' '}
            Nadamy drugie życie wnętrzu twojego samochodu !{' '}
          </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Zostaw wniosek
          </button>
          <div className="slider__garantia">
            <span>
              GWARANTUJEMY ZWROT 100% OPLATY, JESLI KLIENT NIE BEDZIE ZADOWOLONY
              Z EFEKTU PRANIA !!!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
function SliderThree() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className=" sliderKids">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">
            Pranie wózków dziecięcych i fotelików samochodowych
          </h2>
          <p className="slider__text-p"> Dbanie o zdrowie twojego dziecka! </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Zostaw wniosek
          </button>
          <div className="slider__garantia">
            <span>
              GWARANTUJEMY ZWROT 100% OPLATY, JESLI KLIENT NIE BEDZIE ZADOWOLONY
              Z EFEKTU PRANIA !!!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
function Slider4() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className=" sliderMatress">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">Pranie materacy</h2>
          <p className="slider__text-p">
            {' '}
            Usuniemy takie zanieczyszczenia jak kurz, roztocza, pleśń, bakterie,
            które z biegiem czasu gromadzą się w materacu.
          </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Zostaw wniosek
          </button>
          <div className="slider__garantia">
            <span>
              GWARANTUJEMY ZWROT 100% OPLATY, JESLI KLIENT NIE BEDZIE ZADOWOLONY
              Z EFEKTU PRANIA !!!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
function Slider5() {
  const { handleGetForm, isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className="sliderRug">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">Pranie dywanów i wykładzin</h2>
          <p className="slider__text-p">
            {' '}
            Usuwanie kurzu, brudu, sierści zwierząt, włosów.
          </p>
          <button onClick={handleGetForm} className="slider__text-btn">
            Zostaw wniosek
          </button>
          <div className="slider__garantia">
            <span>
              GWARANTUJEMY ZWROT 100% OPLATY, JESLI KLIENT NIE BEDZIE ZADOWOLONY
              Z EFEKTU PRANIA !!!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function SwiperSliders() {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <Swiper
      loop={true}
      spaceBetween={0}
      navigation
      centeredSlides={true}
      slidesPerView={'auto'}
      autoplay={{
        delay: 5500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      modules={[EffectCoverflow, Autoplay, Navigation]}
      onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
    >
      <SwiperSlide>
        <SliderOne />
      </SwiperSlide>
      <SwiperSlide>
        <SliderTwo />
      </SwiperSlide>
      <SwiperSlide>
        <SliderThree />
      </SwiperSlide>
      <SwiperSlide>
        <Slider4 />
      </SwiperSlide>
      <SwiperSlide>
        <Slider5 />
      </SwiperSlide>
      <div className="autoplay-progress" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span ref={progressContent}></span>
      </div>
    </Swiper>
  );
}
export default SwiperSliders;
