import './Services.css';
import main1 from '../../../img/Services/foto15.jpg';
import main2 from '../../../img/Services/car4.jpg';
import main3 from '../../../img/Services/armchair.png';
import main4 from '../../../img/Services/foto14.jpg';
import main5 from '../../../img/Services/cornerclean.png';
import main6 from '../../../img/Services/kidsakolyaska.jpg';
import { Link } from 'react-router-dom';

function LinkMore(props) {
  const { path = '/' } = props;

  return (
    <Link {...props} to={path}>
      {' '}
      <span className="main-services-link">Szczegółowo...</span>
    </Link>
  );
}

function Services() {
  return (
    <>
      <div className="container container-main-service">
        <div className="main-title_uslugi">
          <p className="main-title_uslugi-p">Nasze usługi</p>
          <div className="main-title_uslugi-line"></div>
          <h1>
            Profesjonalne pranie kanap, foteli, materacy, wózków, narożników,
            zasłon, rolet, wykładzin, dywanów, tapicerka samochodowa, tapicerki
            meblowej...
          </h1>
        </div>

        <div className="main__services-all">
          <div className="main__services-all-item">
            <img src={main1} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Pranie kanap</h2>
              <LinkMore path={'/usluga1'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main2} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Tapicerka samochodowa
              </h2>
              <LinkMore path={'/usluga2'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main3} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Pranie foteli</h2>
              <LinkMore path={'/usluga3'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main4} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Pranie materaców</h2>
              <LinkMore path={'/usluga4'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main5} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Pranie narożników</h2>
              <LinkMore path={'/usluga5'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main6} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Pranie chemiczne wózków i fotelików samochodowych
              </h2>
              <LinkMore
                path={'/usluga6'}
                style={{ position: 'relative', top: '-20px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Services;
