import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/foto13.jpg';
import quilon2 from '../../../img/articles/article1.2.jpg';
import WhatsApp from '../../WhatsUp/WhatsappLink';
import { useProject } from '../../../tools/ProviderContext';

export function Cataloge() {
  const [isScroll, setScroll] = useState(true);
  const { notScrollLineUp } = useProject();

  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: -100, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  const toggle = () => {
    setScroll(true);
  };

  return (
    <div className="catalogs">
      <h3>Katalog</h3>
      <ul>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article1"
          >
            <span>Pranie materacy</span>
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article2"
          >
            <span>Pranie dywanów i wykładzin</span>
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article3"
          >
            Pranie tapicerki meblowej
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article4"
          >
            Pranie zasłon, firan, rolet
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article5"
          >
            Pranie tapicerki samochodowej{' '}
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Pranie tapicerki meblowej Legnica</h2>

            <p>
              Mycie mebli tapicerowanych to jedna z najważniejszych czynności
              związanych z higieną w naszych domach i miejscach pracy. Meble
              tapicerowane to nie tylko miejsce wypoczynku, ale często pełnią
              również funkcję miejsca do pracy lub jadalni. Gromadzą zatem
              mnóstwo brudu, bakterii i roztoczy, które mogą mieć negatywny
              wpływ na nasze zdrowie.
            </p>
            <p>
              Jednym z najważniejszych powodów regularnego mycia mebli
              tapicerowanych jest usuwanie z nich zabrudzeń. Meble tapicerowane,
              szczególnie w jasnych kolorach, szybko zaczynają wyglądać na
              brudne i zniszczone. Plamy takie jak plamy spożywcze, plamy
              olejowe czy zaschnięte resztki jedzenia są bardzo trudne do
              usunięcia z powierzchni tapicerki. Regularne pranie pomoże usunąć
              te plamy i przywrócić meblom pierwotny wygląd.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Kolejnym ważnym powodem mycia mebli tapicerowanych jest
              zapobieganie rozwojowi bakterii i grzybów. Jeśli na powierzchni
              mebli tapicerowanych dostanie się brud i wilgoć, mogą namnażać się
              grzyby i bakterie, co może być przyczyną chorób i alergii. Mycie
              mebli tapicerowanych pomaga usunąć te mikroorganizmy, co z kolei
              ma pozytywny wpływ na nasze zdrowie.
            </p>
            <p>
              Trzecim powodem prania mebli tapicerowanych jest pozbycie się
              roztoczy. Roztocza to małe organizmy żyjące w kurzu i brudzie na
              powierzchniach mebli. U wielu osób, zwłaszcza tych, które mają
              problemy z oddychaniem, powodują alergie. Regularne mycie mebli
              tapicerowanych pomoże pozbyć się roztoczy, co z kolei poprawi
              jakość powietrza w naszych domach.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Podsumowując, mycie mebli tapicerowanych jest ważne dla utrzymania
              higieny w naszych domach i miejscach pracy. Pomaga usunąć brud,
              bakterie i roztocza, które mogą negatywnie wpływać na nasze
              zdrowie. Regularne mycie mebli tapicerowanych pozwala zachować ich
              wygląd i jakość na długi czas.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article1() {
  return (
    <div className="body_wrapper">
      <Header />

      <Quilon />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Article1;
