import Karcher from '../Karcher/Karcher';
import Logo from '../Logo/Logo';
import Nav from '../Nav/Nav';
import BurgerBtnMenu from '../BurgerBtnMenu/BurgerBtnMenu';
import './header.css';
import { useProject } from '../../../tools/ProviderContext';
function HeaderMain() {
  const { isMenu, closeMenu, closeBurgerMenu, notScrollBtn, isBurgerMenu } =
    useProject();
  return (
    <>
      <div className={isBurgerMenu ? 'header open' : 'header'}>
        <div className="container">
          <div className="header__wrapper">
            <div className="header__logo">
              <Logo />
              <Karcher />
            </div>

            <div className="header__menu">
              <Nav />
            </div>
            <BurgerBtnMenu />
            <div
              onClick={() => {
                closeMenu();
                closeBurgerMenu();
                notScrollBtn();
              }}
              className={isMenu ? 'backdropMenu' : 'hidden'}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderMain;
